import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Img from 'gatsby-image';

import Link from '../link';

import styles from './project-thumbnail.module.scss';

const ProjectThumbnail = ({ title, slug, thumbnailFluid }) => {
  const articleLink = `/projects/${slug}`;

  return (
    <li className={styles.projectThumbnail}>
      <div className={styles.imageContainer}>
        <GatsbyLink to={articleLink}>
          <Img fluid={thumbnailFluid} className={styles.thumbnail} />
        </GatsbyLink>
      </div>
      <p className={styles.label}>{title}</p>
      <Link variant="secondary" to={articleLink}>Read More &rarr;</Link>
    </li>
  );
};

export default ProjectThumbnail;
