import React from "react"
import { graphql } from "gatsby"

import Container from "../layouts/container"

import ProjectThumbnail from "../components/project-thumbnail"

import { useBreakpoints, breakpointMdOrLarger } from "../utils"
import SVGIconButton from "../components/svg-icon-button"
import { Download } from "../assets/svg"

import styles from "./index.module.scss"

const PageIndex = ({ data }) => {
  const breakpoint = useBreakpoints()
  const isLargerScreen = breakpointMdOrLarger(breakpoint)

  return (
    <Container>
      <p className={styles.paragraph}>
        Please note: for reasons of confidentiality, I am unable to display
        projects that are still ongoing, but the examples shown are indicative
        of my work and process.
      </p>
      <ul className={styles.projects}>
        {data.pagesJson.portfolio.projects.map(({ title, slug, thumbnail }) => (
          <ProjectThumbnail
            title={title}
            slug={slug}
            thumbnailFluid={
              data[`thumbnail_${thumbnail}`].childImageSharp.fluid
            }
          />
        ))}
      </ul>
      {isLargerScreen && (
        <div className={styles.downloadButtonContainer}>
          <SVGIconButton
            to={data.allFile.edges[0].node.publicURL}
            svgIcon={Download}
            label="Download PDF Version"
            variant="brandPrimary"
          />
        </div>
      )}
    </Container>
  )
}

export const portfolioThumbnail = graphql`
  fragment portfolioThumbnail on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query pageData {
    thumbnail_preferences: file(
      relativePath: { eq: "projects/preferences.png" }
    ) {
      ...portfolioThumbnail
    }
    thumbnail_pharma_mockup: file(
      relativePath: { eq: "projects/pharma-mockup.png" }
    ) {
      ...portfolioThumbnail
    }
    thumbnail_my_redburn: file(
      relativePath: { eq: "projects/my-redburn-thumbnail.png" }
    ) {
      ...portfolioThumbnail
    }
    thumbnail_ga_action_shot: file(
      relativePath: { eq: "projects/ga-action-shot.png" }
    ) {
      ...portfolioThumbnail
    }
    allFile(filter: { name: { eq: "rosemaryfenton-portfolio" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    pagesJson(
      portfolio: { projects: { elemMatch: { title: { regex: "/^[A-Z]/" } } } }
    ) {
      portfolio {
        projects {
          slug
          thumbnail
          title
        }
      }
    }
  }
`

export default PageIndex
